@use 'material-icons/iconfont/material-icons.css';
@forward 'styles/material';
@forward 'styles/globals';

@forward '@adnova/jf-ng-components/base';

@use '@adnova/jf-ng-components/config' as *;
@use '@adnova/jf-ng-components/lib';



.actions-buttons-menu {
  margin-bottom: $space-size-xs;
  font-size:  $font-size-x-small;

  button[disabled] {
    cursor: not-allowed;
  }
}


